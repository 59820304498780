<template>
  <div class="fieldset">
    <template v-if="!show.login">
      <h3 class="fieldset-heading">Create Portal Login</h3>
      <p>
        Creating an account is optional. If you decide to create an account you will have access to the Client Portal.
        This is a secure space where you can upload information and documents, manage your appointments
        and view any relevant paperwork.
      </p>
      <p>
        Please note that this is a new feature and functionality is currently limited. We will be adding many
        features over the coming months, including the ability to track the progress of all legal, probate,
        mortgage and insurance related services.
      </p>
    </template>

    <template v-else>
      <h3 class="fieldset-heading">Login Created</h3>
      <p>{{ show.loginMessage }}</p>
    </template>

    <template v-if="show.form">
      <QuestionBase
          :errors="[...errors.email]"
          :question="question.email"
          :valid="!!form.email"
          class="pb-0">
        <div v-if="client" class="alert alert-warning" role="alert"> <strong>Reminder:</strong> You are creating an portal login for {{ clientNameFull(client) }}.

        </div>
        <InputStandardBasic v-model="form.email"
                            :args="inputArgs.email"
                            :errors="errors.email"/>
      </QuestionBase>

      <QuestionBase
          :errors="[...errors.password1, ...errors.password2]"
          :question="question.password1"
          :valid="!!form.password1 && (form.password1 === form.password2)"
          class="pb-0">
        <InputStandardBasic v-model="form.password1"
                            :args="inputArgs.password1"
                            :errors="[ ...errors.password1, ...errors.password2]"/>
      </QuestionBase>

      <QuestionBase
          :errors="[...errors.password2]"
          :question="question.password2"
          :valid="!!form.password2 && (form.password1 === form.password2)"
          class="pb-0">
        <InputStandardBasic v-model="form.password2"
                            :args="inputArgs.password2"
                            :errors="errors.password2"/>
      </QuestionBase>


      <QuestionBase :errors="errors.partner_shared_information_terms" :question="question.partner_shared_information_terms"
                    :valid="form.partner_shared_information_terms">
        <InputCheckBoxBasic v-model="form.partner_shared_information_terms" :args="inputArgs.partner_shared_information_terms"/>
      </QuestionBase>

      <QuestionBase :errors="errors.terms_and_conditions" :question="question.terms_and_conditions"
                    :valid="form.terms_and_conditions">
        <InputCheckBoxBasic v-model="form.terms_and_conditions" :args="inputArgs.terms_and_conditions"/>
      </QuestionBase>

      <div class="button-nav">

        <button class="btn btn-forwards"
                @click="create"
        >Create
        </button>
      </div>

    </template>

    <template v-else-if="show.login">

      <div class="button-nav">
        <button class="btn btn-forwards"
                @click="login"
        >Login
        </button>
      </div>

    </template>

  </div>

</template>

<script>
import {httpQuestionnaire} from "@/services";
import {toast} from '@/mixins/toast'
import {clientHelpers} from '@/mixins/clientHelpers';

import InputStandardBasic from "@/components/common/other/inputs/InputStandardBasic";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";
import InputCheckBoxBasic from "@/components/common/other/inputs/InputCheckBoxBasic";
export default {
  name: 'SignUpForm',
  components: {InputCheckBoxBasic, QuestionBase, InputStandardBasic},
  mixins: [toast, clientHelpers],
  mounted() {
    this.setEmail()
  },
  data() {
    return {
      show: {
        form: true,
        login: false,
        loginMessage: null,
        security: false
      },
      form: {
        email: null,
        password1: null,
        password2: null,
        partner_shared_information_terms: false,
        terms_and_conditions: false,
        security_name_first: null
      },
      question: {
        email: {
          title: 'What is your email address?',
          subTitle: 'Your Email Address will be used as your username, please double check the email address is correct before confirming.',
          tip: null,
        },
        password1: {
          title: 'Create a password:',
          subTitle: null,
          tip: null,
        },
        password2: {
          title: null,
          subTitle: null,
          tip: null,
        },
        partner_shared_information_terms: {
          title: 'Shared Client Information',
          subTitle: 'If you have a partner certain information will be shared across both of your accounts.',
          tip: null,
        },
        terms_and_conditions: {
          title: 'Accept Terms & Conditions',
          subTitle: `Your account will be managed in line with our Privacy Policy and the terms and conditions as set out at https://dm-legal.co.uk.`,
          tip: null,
        },
        security_name_first: {
          title: 'Confirm your first name',
          subTitle: 'This is for security',
          tip: null,
        }
      },
      inputArgs: {
        email: {
          placeholder: 'e.g. myemail@me.com',
          inputClass: 'field-75',
          label: 'Email'
        },
        password1: {
          placeholder: null,
          inputClass: 'field-50',
          label: 'Password',
          type: 'password'
        },
        password2: {
          placeholder: null,
          inputClass: 'field-50',
          label: 'Repeat Password',
          type: 'password'

        },
        partner_shared_information_terms: {
          placeholder: null,
          inputClass: 'field-100',
          label: 'I Accept the Terms and Conditions',
          link: true
        },
        terms_and_conditions: {
          placeholder: null,
          inputClass: 'field-100',
          label: 'I Accept the Terms and Conditions',
          link: true
        },
        security_name_first: {
          placeholder: 'e.g. Joan',
          inputClass: 'field-100',
          label: null
        }
      },
      errors: {
        email: [],
        password1: [],
        password2: [],
        partner_shared_information_terms: [],
        terms_and_conditions: [],
        security_name_first: []
      }
    }
  },
  computed: {
    client() {
      return this.$store.getters.client
    },
  },
  methods: {
    create() {
      this.clearErrors()
      httpQuestionnaire.post('client_sign_up', this.form).then(
          () => {
            this.show.form = false
            this.show.login = true
            this.show.loginMessage = 'Success, your account is now created, you can now proceed to login.'
          }
      ).catch(
          error => {
            if ('code' in error.response.data) {
              if (error.response.data.code === 'already_registered') {
                this.toast('Already registered, please login', 'Error', 'danger')
                this.show.form = false
                this.show.login = true
                this.show.loginMessage = 'You are already registered for this service, please login'
              }
            } else {
              this.handleErrors(error.response.data)
            }
          }
      )
    },
    clearErrors() {
      let keys = Object.keys(this.errors)
      for (let i in keys) {
        this.errors[keys[i]] = []
      }
    },
    handleErrors(errors) {
      let keys = Object.keys(errors)
      for (let i in keys) {
        this.errors[keys[i]] = errors[keys[i]]
      }
    },
    setEmail() {
      // set form email based on client profile
      if (this.$store.getters.client) this.form.email = this.$store.getters.client.profileEmail
    },
    login() {
      this.$store.dispatch('logoutClient')
    }
  }
}
</script>
