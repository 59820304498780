<template>
  <div class="hero-sub-bar-two-header border-bottom-gray-200 bg-white w-100 d-flex flex-column justify-content-center">
    <b-container>
      <div class="hero-sub-bar-two-heading d-flex text-16 font-weight-400">
        <template v-if="questionsLoaded">
          <div v-if="$store.getters.numberOfSections > 0" class="d-flex hero-sub-bar-two-heading-stat pr-30"><i class="pr-16 text-22 text-mute i-Split-Four-Square-Window"></i>{{
              $store.getters.numberOfSections
            }} Sections
          </div>
          <div v-if="$store.getters.numberOfMinutes > 0" class="d-flex hero-sub-bar-two-heading-stat pr-30">
            <i class="pr-16 text-22 text-mute i-Stopwatch"></i> {{ $store.getters.numberOfMinutes }} Minutes
          </div>

          <div v-if="client" class="d-flex hero-sub-bar-two-heading-stat">
            <i class="pr-12 text-22 text-mute i-Administrator"></i> {{ clientNameFull(client) }}
          </div>
        </template>
      </div>
    </b-container>
  </div>
</template>

<script>
import {clientHelpers} from "@/mixins/clientHelpers";

export default {
  name: 'Introduction',
  mixins: [clientHelpers],
  computed: {
    client() {
      return this.$store.getters.client
    },
    questionsLoaded() {
      return this.$store.getters.questionsLoaded
    }
  }
}
</script>
