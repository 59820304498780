<template>
  <div class="d-flex align-items-end flex-wrap">
    <div :class="args.inputClass" class="field-wrapper">
      <b-input-group>
        <b-form-checkbox
            v-model="data"
            :unchecked-value="false"
            @change="change"
        >
          <template #default>
            <span v-if="args.link" class="link text-primary">{{ args.label || 'Accept' }}</span>
            <template v-else>
              {{ args.label || 'Accept' }}
            </template>
          </template>


        </b-form-checkbox>
      </b-input-group>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputCheckBoxBasic',
  props: {
    value: {
      type: Boolean,
      required: false
    },
    args: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  methods: {
    change(event) {
      this.$emit('change', event)
    },
    linkClicked() {
      if (this.data) this.data = !this.data
      this.change(!this.data)
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  }
}
</script>