<template>
  <div class="d-flex align-items-end flex-wrap">
    <div class="field-wrapper" :class="args.inputClass">
      <label v-if="args.label" class="sub-label">{{ args.label }}</label>
      <b-input-group :size="args.size || 'lg'">
        <b-input-group-prepend >
          <slot name="prepend">
            <b-input-group-text v-if="args.prependText">
              {{ args.prependText }}
            </b-input-group-text>
          </slot>
        </b-input-group-prepend>
        <b-input
            v-model="data"
            :placeholder="args.placeholder"
            :type="args.type || 'text'"
            :state="state"
            debounce="500"
            class="form-control standard-input"
            :formatter="args.formatter"
            :lazy-formatter="!!args.formatter"
            :disabled="loading"
            :prefix="args.prefix"
        />
        <b-input-group-append v-if="args.appendButton && args.appendText">
          <b-button @click="$emit('buttonClicked')" :disabled="loading" variant="primary">
            <b-spinner v-if="loading" small></b-spinner>
            {{ args.appendText }}
          </b-button>
        </b-input-group-append>

        <b-input-group-append v-else-if="args.appendText">
          <b-input-group-text>
            {{ args.appendText }}
          </b-input-group-text>
        </b-input-group-append>
      </b-input-group>


      <b-form-invalid-feedback v-if="Array.isArray(errors) && errors.length" :state="state">
        <p v-for="(e, index) in errors" :key="'error'+index">{{ e }}</p>
      </b-form-invalid-feedback>

    </div>


    <div v-if="args.otherFunction" class="add-or flex-shrink-0">
      <label class="fancy-checkbox-container">{{ args.otherFunction.text || 'Other Function' }}
        <input type="checkbox" @click="$emit('otherFunction')">
        <span class="checkmark"></span>
      </label>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputStandardBasic',
  props: {
    value: {
      type: [String, Number],
      required: false
    },
    args: {
      type: Object,
      required: true
    },
    errors: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    },
    state () {
      return this.errors.length ? false : null
    }
  }
}
</script>
