<template>
  <div class="questionnaire-header-wrapper bg-primary w-100 p-16 d-flex flex-column justify-content-center">
    <b-container>
      <b-row>
        <b-col class="questionnaire-header">
          <h3 class="questionnaire-heading mb-16">{{ title }}</h3>
          <h4 class="hero-sub-bar-two-heading text-white-50 text-24 font-weight-400">{{ subTitle }}</h4>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

export default {
  name: 'Header',
  components: {
    // videoPlayer
  },
  props: {
    title: {
      // question title override
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: 'Please complete the questions'
    }
  },
  data() {
    return {
      playerOptions: {
        autoplay: false,
        fluid: true,
        language: 'en',
        playbackRates: [
          0.7,
          1.0,
          1.5,
          2.0
        ],
        poster: require('@/assets/images/questionnaire/video-placeholder-NM.jpg'),
      }
    }
  },
}
</script>
