<template>
  <div
      v-show="showQuestion"
      :class="{ 'invalid': errors.length, 'valid': !errors.length && this.valid && !disabledValid, 'sub-section' : subSection}"
      class="question"
  >

    <div v-if="question.tip" :class="{ 'tip_open': showTip }" class="tip-wrapper">

      <a class="tip-outer" @click="showTip = !showTip">
          <span class="tip-inner">
            <span class="tip-icon"></span>
          </span>
      </a>

      <div class="tip-text">
        <a class="tip-text-close-container">
          <div @click="showTip = !showTip" class="tip-text-close"></div>
        </a>
        <p v-dompurify-html="question.tip"></p>
      </div>
    </div>

    <div v-if="question.title" :class="{'pr-0' : noRightPadding}" class="question-text" v-dompurify-html="question.title"></div>
    <p v-if="question.subTitle" class="question-sub-text" v-dompurify-html="question.subTitle"></p>

    <slot></slot>

    <template v-if="errors.length">
      <div v-for="(error, index) in errors" :key="index" class="question-alert">{{ error }}</div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'QuestionBase',
  props: {
    question: {
      required: true,
      type: Object
    },
    errors: {
      type: [Array],
      required: true
    },
    valid: {
      type: Boolean,
      required: true
    },
    disabledValid: {
      type: Boolean,
      default: false,
    },
    showQuestion: {
      type: Boolean,
      default: true
    },
    subSection: {
      type: Boolean,
      default: false
    },
    noRightPadding: {
      type: Boolean,
      default: false
    },
    addClass: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      showTip: false
    }
  }
}
</script>
